
export default {
  props: {
    data: {},
  },
  filters:{
    setTime(val){
      let tempArr=val.split(' ')
      let timeStr=tempArr[0]
      let timeArr=timeStr.split('-')
      return `${timeArr[2]}-${timeArr[1]}-${timeArr[0]}`
    }
  }
};
