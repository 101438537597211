
export default {
  props: {
    title: String,
    marginTop: {
      type: Number,
    },
  },
  
};
