
export default {
  props: {
    cardData: {},
    showPricing: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
  computed:{
    comImg(){
      return this.$utils.generateResizedImageUrl(this.cardData.main?.image,300)
    },
    comBestIcon(){
      const bestSeller = this.cardData.collection_detail?.find(item => item.name === "Best Seller");
      return bestSeller ? bestSeller.img : '';
    }
  },
  methods: {
    addCompareList(id, e) {
      this.$axios
        .post("home/compare_add", {
          id,
          compare_keys: "keys",
        })
        .then((res) => {
          if (res.code === 1) {
            this.$store.dispatch("getCompareList");
            this.$emit("addCompare", e.target);
          } else {
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .catch(() => {});
    },
    goProduct(cardData) {
      this.$router.push({
        name: "product-code",
        params: { code: cardData.product_code },
      });
      // let routeData = this.$router.resolve({
      //   name: "product-code",
      //   params:{ code: cardData.product_code },
      // });
      // window.open(routeData.href, '_blank');
    },
  },
};
